"use client"

import Image from "next/image";
import Facebook from "@/public/svg/common/social-icons/facebook.svg";
import Instagram from "@/public/svg/common/social-icons/instagram.svg";
import Linkedin from "@/public/svg/common/social-icons/linkedin.svg";
import Whatsapp from "@/public/svg/common/social-icons/whatsapp.svg";
import Youtube from "@/public/svg/common/social-icons/youtube.svg";

import Button from "@/components/button/Button";
import Heading from "@/components/heading/heading";
import Link from "next/link";
import { useEffect, useRef } from "react";

const Contact = () => {
    const SocialLinks = [
        {
            name: Facebook,
            url: "https://www.facebook.com/dimaaktours",
        },
        {
            name: Instagram,
            url: "https://www.instagram.com/dimaaktours/",
        },
        {
            name: Linkedin,
            url: "https://www.linkedin.com/company/dimaaktours/",
        },
        {
            name: Youtube,
            url: "https://www.youtube.com/channel/UCqA1BWL3xcDdBlGoC8TmNBw",
        },
        {
            name: Whatsapp,
            url: "https://wa.me/919886883456?text=Hi,%20I%20need%20your%20help%20to%20plan%20my%20upcoming%20trip.",
        },
    ];
    const Legal = [
        {
            name: "FAQ",
            url: "/faq",
        },
        {
            name: "Privacy policy",
            url: "/privacy-policy",
        },
        {
            name: "Terms & Conditions",
            url: "/terms",
        },
        {
            name: "Cancellation Policy",
            url: "/refund-policy",
        },
    ];
    const Resources = [
        {
            name: "About Us",
            url: "/about-us",
        },
        {
            name: "Blogs",
            url: "/blog",
        },
        {
            name: "Customers Reviews",
            url: "https://bit.ly/dimaakreviews",
        },
        {
            name: "Contact Us",
            url: "/contact-us",
        },
    ];
    const CallUs = [
        {
            name: "Sales: +91 960-009-9777",
            url: "+91 960-009-9777",
        },
        {
            name: "COO: +91 988-688-3456",
            url: "+91 988-688-3456",
        },
        {
            name: "HCX: +91 936-112-7954",
            url: "+91 936-112-7954",
        },
        {
            name: "CEO: +91 961-168-0883",
            url: "+91 961-168-0883",
        },
    ];

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        var fHeight: any;
        if (divRef.current) {
            fHeight = divRef.current.clientHeight
        }

        const height = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        );

        window.addEventListener("scroll", () => {
            if (document.documentElement.scrollTop > (height - fHeight) || document.documentElement.scrollTop < 1000) {
                document.getElementById("observeMe")?.classList?.add("hidden")
            } else {
                document.getElementById("observeMe")?.classList?.remove("hidden")

            }
        })
    }, [])


    return (
        <div className="contact-block grid grid-cols-1 gap-4 lg:grid-cols-5 xl:grid-cols-6">
            <div className="col-span-1 flex flex-col gap-4 lg:col-span-2 xl:col-span-3">
                <Heading as="h6" className="font-semibold">
                    Contact
                </Heading>
                <div className="contact-details flex flex-col items-start gap-3">
                    <address>
                    Dimaak Travel Technologies Private Limited <br/>
                    Ask Towers, Second Floor
                    Plot No.183 Sri Sai Nagar Third Main Road
                    Sri Sai Nagar, Okkiyam Thoraipakkam
                    Chennai 600097, Tamilnadu India

                    </address>
                    <a
                        href={
                            "https://maps.app.goo.gl/fYDjyhpV8tBKM4gj7"
                        }
                        target="_blank"
                    >
                        <Button className="flex items-center" variant="orange">
                            <span>Get Directions</span>
                        </Button>
                    </a>

                    <address>
                    Dimaak Travel Technologies Private Limited <br/>Good Works Infinity Park,21, 2nd Main Rd,
                        Electronics City Phase 1, Electronic City, Bengaluru,
                        Karnataka 560100
                    </address>
                </div>
                <Heading as="h6" className="font-semibold">
                    Social Links
                </Heading>
                <ul className="social-list list flex items-center gap-2">
                    {SocialLinks.map((s, index) => {
                        return (
                            <li className="social-list-item" key={index}>
                                <a href={s.url} target="_blank">
                                    <Image loading="lazy"
                                        className=""
                                        src={s.name}
                                        alt={s.url}
                                        width={24}
                                        height={24}
                                    />
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="col-span-1 flex flex-col gap-4 lg:col-span-1 xl:col-span-1">
                <Heading as="h6" className="font-semibold">
                    Get in Touch
                </Heading>
                <ul className="call-list list flex-col flex gap-3">
                    {CallUs.map((c, index) => {
                        return (
                            <li className="call-list-item" key={index}>
                                <a href={`tel:${c.url}`}>{c.name}</a>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="col-span-1 flex flex-col gap-4 lg:col-span-1 xl:col-span-1">
                <Heading as="h6" className="font-semibold">
                    Company
                </Heading>
                <ul className="resource-list list flex-col flex gap-3">
                    {Resources.map((r, index) => {
                        return (
                            <li className="resource-list-item" key={index}>
                                <a href={r.url}>{r.name}</a>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div ref={divRef} className="col-span-1 flex flex-col gap-4 lg:col-span-1 xl:col-span-1">
                <Heading as="h6" className="font-semibold">
                    Legal
                </Heading>
                <ul className="legal-list list flex-col flex gap-3">
                    {Legal.map((l, index) => {
                        return (
                            <li className="legal-list-item" key={index}>
                                <a href={l.url}>{l.name}</a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Contact;
