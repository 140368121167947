"use client";
import Image from "next/image";
import { MouseEvent, useEffect, useState } from "react";

import ctaMobile from "@/public/png/common/enquiry/cta-mobile.png";
import Close from "@/public/svg/common/header/close.svg";
import Hamburger from "@/public/svg/common/header/hamburger.svg";
import {
    default as LogoMobile,
    default as LogoRed,
} from "@/public/svg/common/header/logo-red.svg";
import Logo from "@/public/svg/common/header/logo.svg";
import Facebook from "@/public/svg/common/social-icons/facebook.svg";
import Instagram from "@/public/svg/common/social-icons/instagram.svg";
import Linkedin from "@/public/svg/common/social-icons/linkedin.svg";
import Phonecall from "@/public/svg/phone-call.svg";
import Whatsapp from "@/public/svg/common/social-icons/whatsapp.svg";
import Buttonwhatsapp from "@/public/svg/common/social-icons/whatsapp.svg";

import Youtube from "@/public/svg/common/social-icons/youtube.svg";

import "./header.scss";

import Button from "@/components/button/Button";
import Heading from "@/components/heading/heading";

import { cn } from "@/utils/twMerge";
import Link from "next/link";
import TwoColumnDropdown from "./TwoColumnDropdown";
interface HeaderProps {
    variant?: "transparent" | "white";
}

const Navigations = [
    {
        name: "Destinations",
        options: [
            [
                { label: "Thailand", link: "/thailand" },
                { label: "Maldives", link: "/maldives" },
                { label: "Bali", link: "/bali" },
                { label: "Singapore", link: "/singapore" },
                { label: "Sri Lanka", link: "/srilanka" },
            ],
            [
                { label: "Malaysia", link: "/malaysia" },
                { label: "Dubai", link: "/dubai" },
                { label: "Vietnam", link: "/vietnam" },
                { label: "Hong Kong", link: "/hongkong" },
                { label: "Andaman", link: "/andaman" },
                
            ],
        ],
    },
    {
        name: "Honeymoon Packages",
        options: [
            [
                {
                    label: "Thailand Honeymoon Packages",
                    link: "/thailand/honeymoon-packages",
                },
                {
                    label: "Maldives Honeymoon Packages",
                    link: "/maldives/honeymoon-packages",
                },
                {
                    label: "Bali Honeymoon Packages",
                    link: "/bali/honeymoon-packages",
                },
                {
                    label: "Singapore Honeymoon Packages",
                    link: "/singapore/honeymoon-packages",
                },
                {
                    label: "Sri Lanka Honeymoon Packages",
                    link: "/srilanka/honeymoon-packages",
                },
            ],
            [
                {
                    label: "Malaysia Honeymoon Packages",
                    link: "/malaysia/honeymoon-packages",
                },
                {
                    label: "Dubai Honeymoon Packages",
                    link: "/dubai/honeymoon-packages",
                },
                {
                    label: "Vietnam Honeymoon Packages",
                    link: "/vietnam/honeymoon-packages",
                },
                {
                    label: "Hong Kong Honeymoon Packages",
                    link: "/hongkong/honeymoon-packages",
                },
                {
                    label: "Andaman Honeymoon Packages",
                    link: "/andaman/honeymoon-packages",
                },
                
            ],
        ],
    },
    {
        name: "Family Packages",
        options: [
            [
                {
                    label: "Thailand Family Packages",
                    link: "/thailand/family-packages",
                },
                {
                    label: "Maldives Family Packages",
                    link: "/maldives/family-packages",
                },
                {
                    label: "Bali Family Packages",
                    link: "/bali/family-packages",
                },
                {
                    label: "Singapore Family Packages",
                    link: "/singapore/family-packages",
                },
                {
                    label: "Sri Lanka Family Packages",
                    link: "/srilanka/family-packages",
                },
            ],
            [
                {
                    label: "Malaysia Family Packages",
                    link: "/malaysia/family-packages",
                },
                {
                    label: "Dubai Family Packages",
                    link: "/dubai/family-packages",
                },
                {
                    label: "Vietnam Family Packages",
                    link: "/vietnam/family-packages",
                },
                {
                    label: "Hong Kong Family Packages",
                    link: "/hongkong/family-packages",
                },
                {
                    label: "Andaman Family Packages",
                    link: "/andaman/family-packages",
                },
                
            ],
        ],
    },
];

const SocialLinks = [
    {
        name: Facebook,
        url: "/",
    },
    {
        name: Instagram,
        url: "/",
    },
    {
        name: Linkedin,
        url: "/",
    },
    {
        name: Youtube,
        url: "/",
    },
    {
        name: Whatsapp,
        url: "/",
    },
];

const Header = (props: HeaderProps) => {
    const { variant = "transparent" } = props;

    const [mobileNav, setMobileNav] = useState(false);

    useEffect(() => {
        if (mobileNav) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [mobileNav]);

    const handleMobileNav = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setMobileNav(!mobileNav);
    };

    return (
        <header
            className={cn(
                "header py-3 z-30",
                variant === "white" ? "static-header" : ""
            )}
        >
            <div className="header-container flex items-center justify-between container mx-auto px-4">
                <Link href="/" className="logo">
                    <Image
                        loading="lazy"
                        className="logo-default"
                        src={Logo}
                        alt="Logo"
                        width={100}
                        height={44}
                    />
                    <Image
                        loading="lazy"
                        className="logo-red"
                        src={LogoRed}
                        alt="Logo"
                        width={100}
                        height={44}
                    />
                </Link>
                <div className="nav-right ml-auto flex items-center gap-6">
                    <nav className="nav h-full">
                        <ul className="nav-list list flex items-center gap-6">
                            {Navigations.map((nav, index) => {
                                return (
                                    <li className="nav-list-item" key={index}>
                                        <TwoColumnDropdown
                                            options={nav.options}
                                            name={nav.name}
                                            white={variant === "white"}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                </div>
                <div className="flex items-center ml-10">
                    <a
                        href="https://wa.me/919600099777?text=Hi,%20I%20am%20Seeking%20Details%20on%20Tour%20Packages."
                        className="flex items-center justify-center gap-3"
                    >
                        <Button
                            className="flex items-center gap-3 p-3"
                            variant="green"
                        >
                            <Image src={Buttonwhatsapp} alt="whatsappimage" />{" "}
                            <span>Get Quote</span>
                        </Button>
                    </a>

                    <figure
                        className="hamburger ml-5"
                        onClick={handleMobileNav}
                    >
                        <Image
                            loading="lazy"
                            src={Hamburger}
                            alt="logo"
                            width={24}
                            height={24}
                        />
                    </figure>
                </div>
                <div
                    className={`mobile-navigation flex-col p-4 gap-6 ${
                        mobileNav ? "active" : ""
                    }`}
                >
                    <div className="mobile-header flex items-center justify-between">
                        <a href="/" className="mobile-logo">
                            <Image
                                loading="lazy"
                                width={72}
                                height={40}
                                src={LogoMobile}
                                alt="logo"
                            />
                        </a>
                        <figure
                            className="hamburger-close ml-auto"
                            onClick={handleMobileNav}
                        >
                            <Image
                                loading="lazy"
                                src={Close}
                                alt="Close"
                                width={36}
                                height={36}
                            />
                        </figure>
                    </div>
                    <ul className="nav-list list flex flex-col gap-4">
                        {Navigations.map((nav, index) => {
                            return (
                                <li className="nav-list-item" key={index}>
                                    <TwoColumnDropdown
                                        options={nav.options}
                                        name={nav.name}
                                        white={variant === "white"}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                    {/* <div className="enquiry-block-mobile mt-12">
                        <div className="enquiry-info gap-2 p-4 flex flex-col items-start">
                            <Heading as="h4">
                                The great gateway, your way
                            </Heading>
                            <p>
                                Save at least 15% on stays worldwide, from
                                relaxing retreats to off-grid adventures
                            </p>
                            <Button
                                className="flex items-center gap-3"
                                variant="green"
                            >
                                <Image loading="lazy"
                                    src={Whatsapp}
                                    alt="Whatsapp"
                                    width={24}
                                    height={24}
                                    
                                />
                                <span>Business WhatsApp</span>
                            </Button>
                        </div>
                        <figure className="enquiry-picture">
                            <Image loading="lazy"
                                src={ctaMobile}
                                alt="circle pattern"
                                className="cta-mobile"
                                
                            />
                        </figure>
                    </div> */}
                    {/* <ul className="social-list list flex-center gap-2">
                        {SocialLinks.map((s, index) => {
                            return (
                                <li className="social-list-item" key={index}>
                                    <a href={s.url}>
                                        <Image loading="lazy"
                                            className=""
                                            src={s.name}
                                            alt={s.url}
                                            width={24}
                                            height={24}
                                            
                                        />
                                    </a>
                                </li>
                            );
                        })}
                    </ul> */}

                    <a
                        href="https://wa.me/919600099777?text=Hi,%20I%20am%20Seeking%20Details%20on%20Tour%20Packages."
                        className="flex items-center justify-center gap-3"
                    >
                        <Button
                            className="flex items-center w-fit gap-3"
                            variant="green"
                        >
                            <Image src={Buttonwhatsapp} alt="whatsappimage" />{" "}
                            <span>Get Quote</span>
                        </Button>
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Header;
