import { cn } from "@/utils/twMerge";

import './button.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: "orange" | "outline" | "white" | "green" | "grey";
    className?: string;
    children?: React.ReactNode;
}

const Button = (props: Props) => {
    const { variant = "orange", className, children } = props;

    return (
        <button
            {...props}
            className={cn("button", `button-${variant}`, className)}
        >
            {children}
        </button>
    );
};

export default Button;
